<template>
  <v-dialog v-model="dialog" width="350">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        elevation="0"
        v-on="on"
        block
        height="30px"
        :disabled="disabled"
        @click="setDate"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card>
      <v-card-title>{{ $t("change_start") }}</v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-date-picker
          first-day-of-week="1"
          :allowed-dates="allowedDates"
          color="primary"
          no-title
          v-model="date"
          :min="today"
          ref="picker"
        >
        </v-date-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="
            dialog = false;
            files = [];
          "
          style="height: 25px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="save"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "ChangeTattooerStart",
  props: ["list", "disabled", "tattooer"],
  data() {
    return { dialog: false, date: null, today: null };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  methods: {
    setDate() {
      this.date = this.ts.from;
    },
    ...mapActions("tattooers", ["changeStart"]),
    save() {
      let formData = {
        from: this.ts.from,
        studio_id: this.ts.studio_id,
        tattooer_id: this.ts.tattooer_id,
        new_from: this.date,
      };
      this.changeStart(formData).then((response) => {
        this.dialog = false;
        this.$alert(this.$t("save_ok"));
        this.$emit("update");
      });
    },
  },
  computed: {
    ts() {
      let ts = this.tattooer.tattooer_studios.find(
        (ts) => ts.studio_id === this.$store.state.auth.user.studio.id
      );
      return ts;
    },
  },
};
</script>